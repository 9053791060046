<template>
	<div class="vld-parent">
	<loading :active="loading" color="#D9F7C5" loader="bars"/>
	</div>
	<div class="p-grid crud-demo">
		<div class="p-col-12">
			<div class="card" id="xyz">                
				<Toast/>
				<Toolbar class="p-mb-4">
					<template v-slot:right>
						<Button label="Nuevo" icon="pi pi-plus" class="p-button-success p-mr-2" @click="openNew" />
					</template>
				</Toolbar>
				<DataTable ref="dt" :value="tarifas" class="p-datatable-sm" dataKey="id_contador_tarifa" :paginator="true" :rows="10" :filters="filters"
							:globalFilterFields="['tarifa']" 
							paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown" :rowsPerPageOptions="[5,10,25]"
							currentPageReportTemplate="Mostrando {first} de {last} de {totalRecords}" :loading="loadingGrd"
							:key="tarifasGrdRefresh">
					<template #header>
						<div class="table-header p-d-flex p-flex-column p-flex-md-row p-jc-md-between">
							<h5 class="p-m-0">Tarifas</h5>
							<span class="p-input-icon-left">
                                <i class="pi pi-search" />
                                <InputText v-model="filters['global'].value" placeholder="Buscar..." />
                            </span>
						</div>
					</template>
					<Column field="tarifa" header="Tarifa" :sortable="true" headerStyle="width: 35%">
					</Column>
					<Column field="consumo_desde" header="Rango de Consumo" :sortable="true" headerStyle="width: 30%">
						<template #body="slotProps">
							{{ slotProps.data.consumo_desde }} ~ {{ slotProps.data.consumo_hasta }} {{slotProps.data.dimensional}}
						</template>					
					</Column>		
					<Column field="costo_unidad_lectura"  header="Costo Unidad" :sortable="true" headerStyle="width: 15%">
						<template #body="slotProps">
							{{formatCurrency(slotProps.data.id_moneda, slotProps.data.costo_unidad_lectura)}}
						</template>					
					</Column>		
					<Column field="costo_unidad_exceso" header="Costo Unidad Exceso" headerStyle="width: 15%">
						<template #body="slotProps">
							{{formatCurrency(slotProps.data.id_moneda, slotProps.data.costo_unidad_exceso)}}
						</template>							
					</Column>					
					<Column>
						<template #body="slotProps">
							<Button icon="pi pi-pencil" class="p-button-rounded p-button-success p-mr-1" @click="edit(slotProps.data)" />
							<Button icon="pi pi-trash" class="p-button-rounded p-button-warning p-mr-1" @click="confirmDelete(slotProps.data)" />
						</template>
					</Column>
				</DataTable>
			</div>
			<Dialog v-model:visible="tarifaDlg" :style="{width: '850px'}"  :modal="true" class="p-fluid">
				<div class="p-fluid p-formgrid p-grid">
					<div class="p-field p-col-12">
						<label for="tarifa">Tarifa</label>
						<InputText size="50" id="tarifa" type="text" v-model="tarifa.tarifa" :class="{'p-invalid': v$.tarifa.tarifa.$error}"/>
						 <small class="p-invalid" style="color: red"  v-if="v$.tarifa.tarifa.$error">Debe proporcionar un nombre para la tarifa!</small>
					</div>			
					<div class="p-field p-col-6">
						<label for="id_moneda">Moneda</label>
						<Dropdown v-model="tarifa.id_moneda" required="true" :options="lkpMonedas" optionLabel="moneda" optionValue="id_moneda" placeholder="Seleccionar..." />
					</div>
					<div class="p-field p-col-6">
						<label for="id_dimensional">Dimensional</label>
						<Dropdown v-model="tarifa.id_dimensional" required="true" :options="lkpDimensionales" optionLabel="unidad" optionValue="id_unidad_medida" placeholder="Seleccionar..." />
					</div>
					<div class="p-field p-col-6">
						<label for="costo_unidad_lectura">Costo Unidad</label>
						<InputNumber v-model="tarifa.costo_unidad_lectura" required="true" mode="decimal" :minFractionDigits="2" :min="0"/>                   										
					</div>
					<div class="p-field p-col-6">
						<label for="costo_unidad_exceso">Costo Unidad Exceso</label>
						<InputNumber v-model="tarifa.costo_unidad_exceso" required="true"  mode="decimal" :minFractionDigits="2" :min="0" />						
					</div>
					<div class="p-field p-col-6">
						<label for="consumo_desde">Consumo Desde</label>
						<InputNumber v-model="tarifa.consumo_desde" required="true" mode="decimal" :minFractionDigits="2" :min="0"/> 						
					</div>
					<div class="p-field p-col-6">
						<label for="consumo_hasta">Consumo Hasta</label>
						<InputNumber v-model="tarifa.consumo_hasta" required="true" mode="decimal" :minFractionDigits="2" :min="0"/>				           				
					</div>		
					<div>
						<Panel class="p-field p-col-12"  header="Información para emisión de cargos">
							<div class="p-formgrid p-grid">
								<div class="p-field p-col-12 p-fluid" style="font-weight: bold;">
									<label for="id_producto_empresa">Producto</label>
									<Dropdown class="p-mr-2" v-model="tarifa.id_producto_empresa" :options="lkpProductos" optionLabel="producto" optionValue="id_producto_empresa"/>
								</div>
								<div class="p-field p-col-6 p-fluid">
									<label for="id_movimiento_tipo" style="font-weight: bold;">Movimiento Tipo</label>
									<Dropdown class="p-mr-2" v-model="tarifa.id_movimiento_tipo" :options="lkpMovsTipos" optionLabel="movimiento_tipo" optionValue="id_movimiento_tipo"/>
								</div>
								<div class="p-field p-col-6 p-fluid">
									<label for="id_movimiento_serie" style="font-weight: bold;">Serie</label>
									<Dropdown class="p-mr-2" v-model="tarifa.id_movimiento_serie" :options="lkpMovTipoSerie" optionLabel="serie" optionValue="id_movimiento_serie"/>
								</div>
								<div class="p-field p-col-8 p-fluid">
									<label for="id_condicion_pago" style="font-weight: bold;">Condición de Pago</label>
									<Dropdown class="p-mr-2" v-model="tarifa.id_condicion_pago" :options="lkpCondicionesPago" optionLabel="condicion_pago" optionValue="id_condicion_pago"/>
								</div>										
								<div class="p-field p-col-12 p-fluid">
									<label for="referencia" style="font-weight: bold;">Referencia</label>
									<InputText id="referencia" v-model="tarifa.referencia" maxlength="100"/>
								</div>
							</div>
						</Panel>
					</div>												
				</div>                                                                                                              								
				<template #footer>
					<Button label="Cerrar" icon="pi pi-times" class="p-button-text" @click="tarifaDlg = false"/>
					<Button label="Guardar" icon="pi pi-check" class="p-button-text" @click="guardarRec"/>						
				</template>
			</Dialog>
			<Dialog v-model:visible="deleteDialog" :style="{width: '450px'}" header="Confirmar" :modal="true">
				<div class="confirmation-content">
				<i class="pi pi-exclamation-triangle p-mr-3" style="font-size: 2rem" />
				<span v-if="tarifa">¿Está seguro que desea eliminar la tarifa seleccionada?<br><b>[{{ tarifa.tarifa }}]</b>?</span>
				</div>
				<template #footer>
					<Button label="No" icon="pi pi-times" class="p-button-text" @click="deleteDialog = false"/>
					<Button label="Sí" icon="pi pi-check" class="p-button-text" @click="deleteRec" />
				</template>
			</Dialog>
		</div>
	</div>

</template>

<script>
import Loading from 'vue-loading-overlay';
// Import stylesheet
import 'vue-loading-overlay/dist/vue-loading.css';
import { useVuelidate } from '@vuelidate/core'
import { required } from '@vuelidate/validators'
import MdsService from '../../service/mds.service';
import InvService from '../../service/inv.service';
import {FilterMatchMode} from 'primevue/api';

export default {
	setup () {
    	return { v$: useVuelidate() }
  	},
	data() {
		return {
			tarifas: null,
			tarifasGrdRefresh: 0,
			complejo: this.$store.state.auth.currentAppCtx.complejo,
			lkpMonedas: this.$store.state.auth.currentAppCtx.Monedas,
			lkpMovsTipos: this.$store.state.auth.currentAppCtx.tiposMovimientoInv,
			lkpMovTipoSerie: null,
			lkpCondicionesPago: this.$store.state.auth.currentAppCtx.CondicionesPago,
			lkpProductos: null,			
			IdMonedaDefault: this.$store.state.auth.currentAppCtx.monedaBase.id_moneda,
			lkpDimensionales: [{id_unidad_medida: "MTS3", unidad:"Mts3"}, {id_unidad_medida: "KHW", unidad:"kWh"}],
			tarifa: {
				id_contador_tarifa: 0,
				id_complejo: this.$store.state.auth.currentAppCtx.complejo.id_complejo,
				id_moneda: this.$store.state.auth.currentAppCtx.monedaBase.id_moneda,
				tarifa: "",
				costo_unidad_lectura: 0,
				costo_unidad_exceso: 0,
				id_dimensional: "MTS3",
				consumo_desde: 0,
				consumo_hasta: 0,
				id_producto_empresa: null,
				id_movimiento_tipo: null,
				id_movimiento_serie: null,
				id_condicion_pago: null, 
				condicion_pago: null,
				dias: 0,
				referencia: null					
			},
            deleteDialog: false,
			tarifaDlg: false,	
			loading: false,	
			loadingGrd: false,
			filters: {'global': {value: null, matchMode: FilterMatchMode.CONTAINS}},
			submitted: false
		}
	},
	validations () {
		return {
			tarifa: { 
				tarifa: {required}
			}, 
		}
	},		
	components: {
		Loading
	},		
	async mounted() {
		this.loadingGrd = true;
		try {
			// let filtro = {
			// 	id_complejo : this.complejo.id_complejo
			// };

			this.tarifas = await MdsService.getContadorTarifas(this.$store.state.auth.currentAppCtx);

			var data = await InvService.getProductos(this.$store.state.auth.currentAppCtx);
			this.lkpProductos = data;

			this.loadingGrd = false;					
		} catch(err) {
			this.loadingGrd = false;
			this.$toast.add({severity:'error', summary: 'Error', detail: data, life: 3000})
		}
	},
	watch: {
		'tarifa.id_movimiento_tipo':  function (newVal, oldVal) {
			if(this.tarifa.id_movimiento_tipo) {
				this.cargarSeriesXTipoMov();
			}
		}
	},		
	methods: {
		openNew() {
			this.tarifa = {
				id_contador_tarifa: 0,
				id_complejo: this.complejo.id_complejo,
				id_moneda: this.IdMonedaDefault,
				tarifa: "",
				costo_unidad_lectura: 0,
				costo_unidad_exceso: 0,
				id_dimensional: "MTS3",
				consumo_desde: 0,
				consumo_hasta: 0,
				id_producto_empresa: '',
				id_movimiento_tipo: this.lkpMovsTipos[0].id_movimiento_tipo,
				id_movimiento_serie: '',
				id_condicion_pago: this.lkpCondicionesPago[0].id_condicion_pago,
				condicion_pago: null,
				dias: 0,
				referencia: null									
			};
			
			this.submitted = false;
			this.tarifaDlg = true;		
		},
		edit(registro) {
			this.tarifa = registro;
			this.submitted = false;
			this.tarifaDlg = true;						
		},
		confirmDelete(registro) {
			this.tarifa = registro;
			this.deleteDialog = true;
		},
		async guardarRec(){		

			const isFormCorrect = await this.v$.$validate();

			if (!isFormCorrect) return;

			this.loading = true;
			try {
				var esNuevo = (this.tarifa.id_contador_tarifa == 0);
				var data = await MdsService.registrarTarifa(this.$store.state.auth.currentAppCtx, this.tarifa);				
				if(esNuevo) {
					this.tarifa.id_contador_tarifa = data.id_contador_tarifa;
					this.tarifas.push(JSON.parse(JSON.stringify(this.tarifa)));
				} else {					
					for (var i =0; i < this.tarifas.length; i++) {
						if (this.tarifas[i].id_contador_tarifa == this.tarifa.id_contador_tarifa) {
							this.tarifas[i] = JSON.parse(JSON.stringify(this.tarifa));							
							break;
						}
					}					
				}
				this.tarifa = {};
				this.tarifasGrdRefresh++;			
				this.tarifaDlg = false;
				this.loading = false;	
				this.submitted = false;			
			} catch (error) {
				this.$toast.add({severity:'error', summary: 'Error', detail: error, life: 3000})
				this.tarifaDlg = false;
				this.submitted = false;
				this.loading = false;
			}
		},		
		formatCurrency(moneda, value) {
			if(value>=0)
				return value.toLocaleString('en-US', {style: 'currency', currency: moneda});
			return;
		},				
		async deleteRec() {
			this.deleteDialog = false;
			try {
				var enUso = await MdsService.tarifaEnUso(this.$store.state.auth.currentAppCtx, this.tarifa);

				if(enUso) {
					this.$toast.add({severity:'warn', summary: 'Advertencia', detail: 'No es posible remover esta tarifa por que tiene contadores asociados!', life: 3000})					
					return;
				}
				this.loadingGrd = true;
				await MdsService.eliminarContadorTarifa(this.$store.state.auth.currentAppCtx, this.tarifa);

				this.tarifas = this.tarifas.filter(val => val.id_contador_tarifa !== this.tarifa.id_contador_tarifa);
				this.tarifa = {};
				this.loadingGrd = false;
				this.$toast.add({severity:'success', summary: 'Exito', detail: 'La tarifa ha sido removida!', life: 3000})
			} catch (err) {				
				this.loadingGrd = false;
				this.$toast.add({severity:'error', summary: 'Error', detail: err, life: 3000})
			}			

		},
		async cargarSeriesXTipoMov() {
			try {
				this.loadingGrd = true;

				var mov_tipo = this.lkpMovsTipos.find((t)=> { return (t.id_movimiento_tipo == this.tarifa.id_movimiento_tipo) });
				var series = await InvService.getSeriesTipoMovimiento(this.$store.state.auth.currentAppCtx, mov_tipo.id_movimiento_tipo_cat);

				this.lkpMovTipoSerie = series;

				if(this.lkpMovTipoSerie.length > 0) {	
					
					if (!this.tarifa.id_movimiento_serie) {
						this.tarifa.id_movimiento_serie = this.lkpMovTipoSerie[0].id_movimiento_serie;
					}
				}
				this.loadingGrd = false; 
			} catch (err) {				
				this.loadingGrd = false;
				this.$toast.add({severity:'error', summary: 'Error', detail: err, life: 3000})
			}					
		},			
	}
}
</script>

<style scoped lang="scss">
.table-header {
	display: flex;
	justify-content: space-between;
}

.product-image {
	width: 100px;
	box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}

.p-dialog .product-image {
	width: 150px;
	margin: 0 auto 2rem auto;
	display: block;
}

.confirmation-content {
	display: flex;
	align-items: center;
	justify-content: center;
}

.product-badge {
	border-radius: 2px;
	padding: .25em .5rem;
	text-transform: uppercase;
	font-weight: 700;
	font-size: 12px;
	letter-spacing: .3px;

	&.status-instock {
		background: #C8E6C9;
		color: #256029;
	}

	&.status-outofstock {
		background: #FFCDD2;
		color: #C63737;
	}

	&.status-lowstock {
		background: #FEEDAF;
		color: #8A5340;
	}
}
/* Responsive */
.p-datatable-customers .p-datatable-tbody > tr > td .p-column-title {
	display: none;
}

@media screen and (max-width: 960px) {
	::v-deep(.p-datatable) {
		&.p-datatable-customers {
			.p-datatable-thead > tr > th,
			.p-datatable-tfoot > tr > td {
				display: none !important;
			}

			.p-datatable-tbody > tr {
				border-bottom: 1px solid var(--surface-d);

				> td {
					text-align: left;
					display: block;
					border: 0 none !important;
					width: 100% !important;
					float: left;
					clear: left;
					border: 0 none;

					&:last-child {
						text-align: center;
					}

					.p-column-title {
						padding: .4rem;
						min-width: 30%;
						display: inline-block;
						margin: -.4rem 1rem -.4rem -.4rem;
						font-weight: bold;
					}

					.p-progressbar {
						margin-top: .5rem;
						display: inline-block;
						width: 60%;
					}

					.p-rating {
						display: inline-block;
					}
				}
			}

			.p-datatable-tbody > tr.p-rowgroup-footer{
				display: flex;
			}
		}
	}
}

::v-deep(.p-toolbar) {
	flex-wrap: wrap;
	.p-button {
		margin-bottom: .25rem;
	}
}
</style>
